import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_ATTACHMENT_DATA_START,
    GET_ATTACHMENT_DATA_FAILED,
    GET_ATTACHMENT_DATA_SUCCESS,
} from '../actions/actionTypes';
import { request } from '../helpers/api';
import { API_DOMAIN } from '../helpers/constants';

function* handleGetAttachmentsData(action) {
    try {
        const data = yield call(
            request.get,
            `${API_DOMAIN}/storage/media-route/${action.payload}/`,
        );
        if (data) {
            return yield put({
                type: GET_ATTACHMENT_DATA_SUCCESS,
                payload: data,
            });
        }
        yield put({
            type: GET_ATTACHMENT_DATA_FAILED,
            error: data,
        });
    } catch (error) {
        return yield put({
            type: GET_ATTACHMENT_DATA_FAILED,
            errorMessage: error?.message,
        });
    }
}

export default function* rootSaga() {
    yield takeLatest(GET_ATTACHMENT_DATA_START, handleGetAttachmentsData);
}
