import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { checkIfTest } from '../../../helpers/m';
import User from '../../../helpers/user';
import { CHAPTERS, PEREKRESTOK_ROUTES } from '../../../helpers/constants';
import NotificationIcons from '../NotificationIcons';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as LogoImg } from '../../../assets/perekrestok/perekrestokLogo.svg';
import HeaderSearchComponent from '../ConceptHeaderSearch';
import MoreMenu from '../MoreMenu';
import styles from './perekrestokHeader.module.scss';

const PerekrestokHeader = () => {
    const { token, logOut } = useContext(AuthContext);
    const isTest = checkIfTest();
    const isLoggedIn = isTest ? User.isLoggedIn() : token;
    const profile = useSelector((state) => state.profileReducer.profile);
    const photo = profile?.photo || '';
    const history = useHistory();
    const location = useLocation();

    const onClickLogout = () => {
        User.logout();
        if (!isTest) {
            return logOut().finally(() => window.location.replace('/login'));
        }
        return window.location.replace('/login');
    };

    return (
        <div className={styles.appHeader}>
            <div className={styles.appHeaderInner}>
                <div className={styles.headerFirstBlock}>
                    <div className={styles.headerMenuIconBlock}>
                        <MenuIcon className={styles.headerMenuIcon} />
                    </div>
                    <Link to="/perekrestok" className={styles.headerLogoBlock}>
                        <LogoImg className={styles.headerLogo} />
                    </Link>
                </div>
                {isLoggedIn && (
                    <>
                        <div className={styles.headerNavbar}>
                            {PEREKRESTOK_ROUTES.map((item) => (
                                <Link
                                    key={item.url}
                                    to={item.url}
                                    className={styles.headerLink}
                                >
                                    {item.title}
                                    {location?.pathname.includes(item.url) && (
                                        <div
                                            className={styles.headerLinkActive}
                                        />
                                    )}
                                </Link>
                            ))}
                            <HeaderSearchComponent
                                isPerek
                                chapterId={CHAPTERS.PEREK}
                            />
                        </div>
                        <div className={styles.headerSecondBlock}>
                            <div className={styles.notifyIconsBlock}>
                                <NotificationIcons isDarkGrey />
                            </div>
                            <div className={styles.profileBlock}>
                                <MoreMenu
                                    items={[
                                        {
                                            name: 'Профиль',
                                            onClick: () =>
                                                history.push('/profile'),
                                        },
                                        {
                                            name: 'Выйти',
                                            onClick: onClickLogout,
                                        },
                                    ]}
                                    customIcon={
                                        photo ? (
                                            <img
                                                src={photo}
                                                className={styles.photo}
                                                alt="profile"
                                            />
                                        ) : (
                                            <div className={styles.photo} />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PerekrestokHeader;
