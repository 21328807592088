import {
    GET_ATTACHMENT_DATA_START,
    GET_ATTACHMENT_DATA_SUCCESS,
    GET_ATTACHMENT_DATA_FAILED,
    CLEAR_ATTACHMENT_DATA,
} from '../actions/actionTypes';

const initialState = {
    data: {},
    fetching: false,
    errorMessage: false,
};

const attachmentsReducer = (state = initialState, { type, payload, error }) => {
    switch (type) {
        case GET_ATTACHMENT_DATA_START:
            return {
                ...state,
                fetching: true,
                errorMessage: false,
            };
        case GET_ATTACHMENT_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                errorMessage: false,
                data: payload,
            };
        case GET_ATTACHMENT_DATA_FAILED:
            return {
                ...state,
                fetching: false,
                errorMessage: error?.message || 'Ошибка получения приложения',
            };
        case CLEAR_ATTACHMENT_DATA:
            return initialState;
        default:
            return state;
    }
};

export default attachmentsReducer;
