import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import User from '../../../helpers/user';
import { checkIfTest, toMessageDate } from '../../../helpers/m';
import {
    getUserNotifications,
    setUserNotificationsRead,
    setUserNotificationsReadAll,
} from '../../../actions/notificationAction';
import { ReactComponent as MessageIcon } from '../../../assets/icons/message.svg';
import { ReactComponent as BellIcon } from '../../../assets/icons/bell.svg';
import styles from './notificationIcons.module.scss';
import { updateProdToken } from '../../../helpers/api';

const REFRESH_TIMEOUT = 15000;

const NotificationIcons = ({ isWhite = false, isDarkGrey = false }) => {
    const [openedNotify, setOpenedNotify] = useState(false);
    const { token } = useContext(AuthContext);
    const isTest = checkIfTest();
    const isLoggedIn = isTest ? User.isLoggedIn() : !!token;
    const dispatch = useDispatch();
    const history = useHistory();
    const getNotifications = () => dispatch(getUserNotifications());
    const fetching = useSelector((state) => state.notificationReducer.fetching);
    let notificationList = useSelector(
        (state) => state.notificationReducer.data,
    );
    notificationList = notificationList.sort((a, b) =>
        a.chapter && b.chapter ? a.chapter.localeCompare(b.chapter) : 0,
    );
    const tasks = notificationList.filter((n) => n.type === 'new_tasks');
    const messages = notificationList.filter((n) => n.type === 'new_messages');
    const tasksLength = tasks.length;
    const mesLength = messages.length;
    const openedMessages = openedNotify === 'message';

    useEffect(() => {
        if (isLoggedIn) {
            startRefreshing();
        }
    }, [isLoggedIn]);

    const startRefreshing = async () => {
        if (!isLoggedIn) return;
        try {
            await updateProdToken();
            setTimeout(() => getNotifications(), 3000);
        } catch (e) {
            console.error('startRefreshing', e);
        }

        const randomStagger = 15000 * Math.random();
        setTimeout(() => startRefreshing(), REFRESH_TIMEOUT + randomStagger);
    };

    const onClickMessage = () => {
        if (openedNotify === 'message') {
            setOpenedNotify(false);
        } else {
            setOpenedNotify('message');
        }
    };

    const onClickTask = () => {
        if (openedNotify === 'task') {
            setOpenedNotify(false);
        } else {
            setOpenedNotify('task');
        }
    };

    const setRead = (arr) => {
        if (fetching) return true;
        if (!arr || (arr && arr.length < 1)) return true;
        dispatch(setUserNotificationsRead(arr));
    };

    const onClickOnTitle = (el) => {
        setRead([el.id]);
        setOpenedNotify(false);

        if (el.page_bug_report?.id) {
            return history.push(`/errors?id=${el.page_bug_report?.id}`);
        }

        if (el.article_id) {
            history &&
                history.push(
                    `/knowledgebase/categories/undefined/articles/${el.article_id}`,
                );
        } else {
            history && history.push(`/letter/${el.info_message_id}`);
        }
    };

    const setReadAll = (type) => {
        if (fetching) return true;
        dispatch(setUserNotificationsReadAll(type));
    };

    const NotifyList = ({ isMessages }) => {
        let currentBlock = '';
        const arr = isMessages ? messages : tasks;

        return arr.map((item, idx) => {
            let renderTitle = item.chapter !== currentBlock;
            currentBlock = item.chapter;
            return (
                <div key={`${item.id}-${idx}`} className={styles.notifyElement}>
                    {renderTitle && (
                        <div className={styles.groupTitle}>
                            <span>{item.get_chapter_display || ''}</span>
                            <div className={styles.groupHr} />
                        </div>
                    )}
                    <div className={styles.elementInfo}>
                        <span
                            className={styles.elementTitle}
                            onClick={() => onClickOnTitle(item)}
                        >
                            {item.info_message_name || item.article_name || ''}
                        </span>
                        <span
                            className={styles.elementComment}
                            onClick={() => onClickOnTitle(item)}
                        >
                            {item.info_message_comment ||
                                item.article_comment ||
                                ''}
                        </span>
                        <div className={styles.elementDetails}>
                            <span className={styles.elementCategory}>
                                {item.get_category_display || ''}
                            </span>
                            <span className={styles.elementDate}>
                                {`От ${toMessageDate(item.create_dt)}`}
                            </span>
                            <span
                                className={styles.elementAction}
                                onClick={() => setRead([item.id])}
                            >
                                Скрыть
                            </span>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div
            tabIndex={0}
            onBlur={() => setOpenedNotify(false)}
            className={`${styles.notifyBlock} ${
                isWhite ? styles.whiteIcons : ''
            } ${isDarkGrey ? styles.darkGreyIcons : ''}`}
        >
            <div className={styles.messageIconWrapper} onClick={onClickMessage}>
                <MessageIcon
                    className={`${styles.messIcon} ${
                        openedNotify === 'message' && styles.messIconActive
                    }`}
                />
                {!!mesLength && <div className={styles.messNotify} />}
            </div>
            <div className={styles.taskIconWrapper} onClick={onClickTask}>
                <BellIcon
                    className={`${styles.taskIcon} ${
                        openedNotify === 'task' && styles.taskIconActive
                    }`}
                />
                {!!tasksLength && <div className={styles.taskNotify} />}
            </div>
            {openedNotify && (
                <div className={styles.popupNotify}>
                    {openedMessages ? (
                        <>
                            <div className={styles.notifyBlockHeader}>
                                <MessageIcon className={styles.msgIcon} />
                                <span>Новые сообщения</span>
                            </div>
                            {messages && messages.length > 0 ? (
                                <>
                                    <NotifyList isMessages={true} />
                                    <div className={styles.hideAllBlock}>
                                        <span
                                            className={styles.hideAllBtn}
                                            onClick={() =>
                                                setReadAll('new_messages')
                                            }
                                        >
                                            Скрыть все уведомления
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <span className={styles.emptyText}>
                                    Уведомлений нет
                                </span>
                            )}
                        </>
                    ) : (
                        <>
                            <div className={styles.notifyBlockHeader}>
                                <BellIcon className={styles.bellIcon} />
                                <span>Новые задачи</span>
                            </div>
                            {tasks && tasks.length > 0 ? (
                                <>
                                    <NotifyList />
                                    <div className={styles.hideAllBlock}>
                                        <span
                                            className={styles.hideAllBtn}
                                            onClick={() =>
                                                setReadAll('new_tasks')
                                            }
                                        >
                                            Скрыть все уведомления
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <span className={styles.emptyText}>
                                    Задач нет
                                </span>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationIcons;
