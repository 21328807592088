import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const EyeIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 20 18">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.109987 8.05422C1.95424 3.31648 5.75922 0.274475 10 0.274475C14.2408 0.274475 18.0458 3.31648 19.89 8.05422C20.0367 8.43095 20.0367 8.85428 19.89 9.23101C18.0458 13.9687 14.2408 17.0108 10 17.0108C5.75922 17.0108 1.95424 13.9687 0.109987 9.23101C-0.0366622 8.85428 -0.0366622 8.43095 0.109987 8.05422ZM10 15.0753C13.419 15.0753 16.5537 12.6036 18.1499 8.64261C16.5537 4.68161 13.419 2.20996 10 2.20996C6.58095 2.20996 3.44625 4.68161 1.85009 8.64261C3.44625 12.6036 6.58095 15.0753 10 15.0753ZM10 12.4567C8.02123 12.4567 6.41711 10.7491 6.41711 8.64261C6.41711 6.53617 8.02123 4.82856 10 4.82856C11.9788 4.82856 13.5829 6.53617 13.5829 8.64261C13.5829 10.7491 11.9788 12.4567 10 12.4567ZM10 10.5212C10.9746 10.5212 11.7647 9.68012 11.7647 8.64261C11.7647 7.60511 10.9746 6.76405 10 6.76405C9.02538 6.76405 8.23529 7.60511 8.23529 8.64261C8.23529 9.68012 9.02538 10.5212 10 10.5212Z"
        />
    </SvgIcon>
);
