export const TOGGLE_NAV_BAR = 'TOGGLE_NAV_BAR';
export const CHANGE_NEWS_PAGE_SIZE = 'CHANGE_NEWS_PAGE_SIZE';
export const CHANGE_NEWS_PAGE = 'CHANGE_NEWS_PAGE';
export const GET_NEWS_LIST_START = 'GET_NEWS_LIST_START';
export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS';
export const GET_NEWS_LIST_FAILED = 'GET_NEWS_LIST_FAILED';
export const ERROR_MESSAGE_UPDATE = 'ERROR_MESSAGE_UPDATE';
export const SUCCESS_MESSAGE_UPDATE = 'SUCCESS_MESSAGE_UPDATE';
export const WARNING_MESSAGE_UPDATE = 'WARNING_MESSAGE_UPDATE';
export const GET_NEWS_DETAILS_START = 'GET_NEWS_DETAILS_START';
export const GET_NEWS_DETAILS_SUCCESS = 'GET_NEWS_DETAILS_SUCCESS';
export const GET_NEWS_DETAILS_FAILED = 'GET_NEWS_DETAILS_FAILED';
export const CREATE_NEWS_START = 'CREATE_NEWS_START';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAILED = 'CREATE_NEWS_FAILED';
export const UPDATE_NEWS_START = 'UPDATE_NEWS_START';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAILED = 'UPDATE_NEWS_FAILED';
export const DELETE_NEWS_START = 'DELETE_NEWS_START';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILED = 'DELETE_NEWS_FAILED';
export const CLEAR_NEWS_DETAILS = 'CLEAR_NEWS_DETAILS';
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';
export const GET_CATEGORY_DETAILS_START = 'GET_CATEGORY_DETAILS_START';
export const GET_CATEGORY_DETAILS_SUCCESS = 'GET_CATEGORY_DETAILS_SUCCESS';
export const GET_CATEGORY_DETAILS_FAILED = 'GET_CATEGORY_DETAILS_FAILED';
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';
export const GET_CATEGORY_EDIT_DETAILS_START =
    'GET_CATEGORY_EDIT_DETAILS_START';
export const GET_CATEGORY_EDIT_DETAILS_SUCCESS =
    'GET_CATEGORY_EDIT_DETAILS_SUCCESS';
export const GET_CATEGORY_EDIT_DETAILS_FAILED =
    'GET_CATEGORY_EDIT_DETAILS_FAILED';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';
export const CLEAR_CATEGORY_DATA = 'CLEAR_CATEGORY_DATA';
export const CLEAR_CATEGORY_EDIT_DATA = 'CLEAR_CATEGORY_EDIT_DATA';
export const GET_LETTERS_DATES_START = 'GET_LETTERS_DATES_START';
export const GET_LETTERS_DATES_SUCCESS = 'GET_LETTERS_DATES_SUCCESS';
export const GET_LETTERS_DATES_FAILED = 'GET_LETTERS_DATES_FAILED';
export const GET_LETTERS_UNPUBLISHED_START = 'GET_LETTERS_UNPUBLISHED_START';
export const GET_LETTERS_UNPUBLISHED_SUCCESS =
    'GET_LETTERS_UNPUBLISHED_SUCCESS';
export const GET_LETTERS_UNPUBLISHED_FAILED = 'GET_LETTERS_UNPUBLISHED_FAILED';
export const GET_LETTER_DETAILS_START = 'GET_LETTER_DETAILS_START';
export const GET_LETTER_DETAILS_SUCCESS = 'GET_LETTER_DETAILS_SUCCESS';
export const GET_LETTER_DETAILS_FAILED = 'GET_LETTER_DETAILS_FAILED';
export const GET_LETTERS_LIST_BY_DATE_START = 'GET_LETTERS_LIST_BY_DATE_START';
export const GET_LETTERS_LIST_BY_DATE_SUCCESS =
    'GET_LETTERS_LIST_BY_DATE_SUCCESS';
export const GET_LETTERS_LIST_BY_DATE_FAILED =
    'GET_LETTERS_LIST_BY_DATE_FAILED';
export const REMOVE_ITEM_FROM_LETTER_DATA_BY_ID =
    'REMOVE_ITEM_FROM_LETTER_DATA_BY_ID';
export const SET_LETTER_FAVOURITE_START = 'SET_LETTER_FAVOURITE_START';
export const SET_LETTER_FAVOURITE_SUCCESS = 'SET_LETTER_FAVOURITE_SUCCESS';
export const SET_LETTER_FAVOURITE_FAILED = 'SET_LETTER_FAVOURITE_FAILED';
export const REMOVE_LETTER_FAVOURITE_START = 'REMOVE_LETTER_FAVOURITE_START';
export const REMOVE_LETTER_FAVOURITE_SUCCESS =
    'REMOVE_LETTER_FAVOURITE_SUCCESS';
export const REMOVE_LETTER_FAVOURITE_FAILED = 'REMOVE_LETTER_FAVOURITE_FAILED';
export const SET_LETTER_LIKE_START = 'SET_LETTER_LIKE_START';
export const SET_LETTER_LIKE_SUCCESS = 'SET_LETTER_LIKE_SUCCESS';
export const SET_LETTER_LIKE_FAILED = 'SET_LETTER_LIKE_FAILED';
export const REMOVE_LETTER_LIKE_START = 'REMOVE_LETTER_LIKE_START';
export const REMOVE_LETTER_LIKE_SUCCESS = 'REMOVE_LETTER_LIKE_SUCCESS';
export const REMOVE_LETTER_LIKE_FAILED = 'REMOVE_LETTER_LIKE_FAILED';
export const SET_LETTER_DISLIKE_START = 'SET_LETTER_DISLIKE_START';
export const SET_LETTER_DISLIKE_SUCCESS = 'SET_LETTER_DISLIKE_SUCCESS';
export const SET_LETTER_DISLIKE_FAILED = 'SET_LETTER_DISLIKE_FAILED';
export const REMOVE_LETTER_DISLIKE_START = 'REMOVE_LETTER_DISLIKE_START';
export const REMOVE_LETTER_DISLIKE_SUCCESS = 'REMOVE_LETTER_DISLIKE_SUCCESS';
export const REMOVE_LETTER_DISLIKE_FAILED = 'REMOVE_LETTER_DISLIKE_FAILED';
export const CLEAR_LETTER_DETAILS = 'CLEAR_LETTER_DETAILS';
export const COPY_ONE_LETTER_START = 'COPY_ONE_LETTER_START';
export const COPY_ONE_LETTER_SUCCESS = 'COPY_ONE_LETTER_SUCCESS';
export const COPY_ONE_LETTER_FAILED = 'COPY_ONE_LETTER_FAILED';
export const ADD_TO_CHAIN_LETTER_START = 'ADD_TO_CHAIN_LETTER_START';
export const ADD_TO_CHAIN_LETTER_SUCCESS = 'ADD_TO_CHAIN_LETTER_SUCCESS';
export const ADD_TO_CHAIN_LETTER_FAILED = 'ADD_TO_CHAIN_LETTER_FAILED';
export const MASS_REMOVE_LETTERS_START = 'MASS_REMOVE_LETTERS_START';
export const MASS_REMOVE_LETTERS_SUCCESS = 'MASS_REMOVE_LETTERS_SUCCESS';
export const MASS_REMOVE_LETTERS_FAILED = 'MASS_REMOVE_LETTERS_FAILED';
export const GET_LETTERS_CHAIN_LIST_START = 'GET_LETTERS_CHAIN_LIST_START';
export const GET_LETTERS_CHAIN_LIST_SUCCESS = 'GET_LETTERS_CHAIN_LIST_SUCCESS';
export const GET_LETTERS_CHAIN_LIST_FAILED = 'GET_LETTERS_CHAIN_LIST_FAILED';
export const CHANGE_LETTER_START = 'CHANGE_LETTER_START';
export const CHANGE_LETTER_SUCCESS = 'CHANGE_LETTER_SUCCESS';
export const CHANGE_LETTER_FAILED = 'CHANGE_LETTER_FAILED';
export const UPDATE_LETTER_STATUS = 'UPDATE_LETTER_STATUS';
export const GET_COMMENTS_START = 'GET_COMMENTS_START';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED';
export const GET_ADMIN_COMMENTS_START = 'GET_ADMIN_COMMENTS_START';
export const GET_ADMIN_COMMENTS_SUCCESS = 'GET_ADMIN_COMMENTS_SUCCESS';
export const GET_ADMIN_COMMENTS_FAILED = 'GET_ADMIN_COMMENTS_FAILED';
export const CREATE_COMMENT_START = 'CREATE_COMMENT_START';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILED = 'CREATE_COMMENT_FAILED';
export const UPDATE_COMMENT_START = 'UPDATE_COMMENT_START';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILED = 'UPDATE_COMMENT_FAILED';
export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED';
export const CREATE_ADMIN_COMMENT_START = 'CREATE_ADMIN_COMMENT_START';
export const CREATE_ADMIN_COMMENT_SUCCESS = 'CREATE_ADMIN_COMMENT_SUCCESS';
export const CREATE_ADMIN_COMMENT_FAILED = 'CREATE_ADMIN_COMMENT_FAILED';
export const UPDATE_ADMIN_COMMENT_START = 'UPDATE_ADMIN_COMMENT_START';
export const UPDATE_ADMIN_COMMENT_SUCCESS = 'UPDATE_ADMIN_COMMENT_SUCCESS';
export const UPDATE_ADMIN_COMMENT_FAILED = 'UPDATE_ADMIN_COMMENT_FAILED';
export const DELETE_ADMIN_COMMENT_START = 'DELETE_ADMIN_COMMENT_START';
export const DELETE_ADMIN_COMMENT_SUCCESS = 'DELETE_ADMIN_COMMENT_SUCCESS';
export const DELETE_ADMIN_COMMENT_FAILED = 'DELETE_ADMIN_COMMENT_FAILED';
export const CLEAR_COMMENTS_DETAILS = 'CLEAR_COMMENTS_DETAILS';
export const CLEAR_ADMIN_COMMENTS_DETAILS = 'CLEAR_ADMIN_COMMENTS_DETAILS';
export const GET_LETTERS_DETAILS_START = 'GET_LETTERS_DETAILS_START';
export const GET_LETTERS_DETAILS_SUCCESS = 'GET_LETTERS_DETAILS_SUCCESS';
export const GET_LETTERS_DETAILS_FAILED = 'GET_LETTERS_DETAILS_FAILED';
export const CREATE_LETTERS_START = 'CREATE_LETTERS_START';
export const CREATE_LETTERS_SUCCESS = 'CREATE_LETTERS_SUCCESS';
export const CREATE_LETTERS_FAILED = 'CREATE_LETTERS_FAILED';
export const UPDATE_LETTERS_START = 'UPDATE_LETTERS_START';
export const UPDATE_LETTERS_SUCCESS = 'UPDATE_LETTERS_SUCCESS';
export const UPDATE_LETTERS_FAILED = 'UPDATE_LETTERS_FAILED';
export const CLEAR_LETTERS_DETAILS = 'CLEAR_LETTER_DETAILS';
export const GET_LETTERS_LIST_START = 'GET_LETTERS_LIST_START';
export const GET_LETTERS_LIST_SUCCESS = 'GET_LETTERS_LIST_SUCCESS';
export const GET_LETTERS_LIST_FAILED = 'GET_LETTERS_LIST_FAILED';

export const GET_FUNCTIONS_LIST_START = 'GET_FUNCTIONS_LIST_START';
export const GET_FUNCTIONS_LIST_SUCCESS = 'GET_FUNCTIONS_LIST_SUCCESS';
export const GET_FUNCTIONS_LIST_FAILURE = 'GET_FUNCTIONS_LIST_FAILURE';
export const GET_CATEGORIES_LIST_START = 'GET_CATEGORIES_LIST_START';
export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';
export const GET_CATEGORIES_LIST_FAILURE = 'GET_CATEGORIES_LIST_FAILURE';
export const GET_HIERARCHICAL_CATEGORIES_LIST_START =
    'GET_HIERARCHICAL_CATEGORIES_LIST_START';
export const GET_HIERARCHICAL_CATEGORIES_LIST_SUCCESS =
    'GET_HIERARCHICAL_CATEGORIES_LIST_SUCCESS';
export const GET_HIERARCHICAL_CATEGORIES_LIST_FAILURE =
    'GET_HIERARCHICAL_CATEGORIES_LIST_FAILURE';
export const GET_CATEGORIES_WITH_ARTICLES_START =
    'GET_CATEGORIES_WITH_ARTICLES_START';
export const GET_CATEGORIES_WITH_ARTICLES_SUCCESS =
    'GET_CATEGORIES_WITH_ARTICLES_SUCCESS';
export const GET_CATEGORIES_WITH_ARTICLES_FAILURE =
    'GET_CATEGORIES_WITH_ARTICLES_FAILURE';

export const GET_ARTICLES_DATES_START = 'GET_ARTICLES_DATES_START';
export const GET_ARTICLES_DATES_SUCCESS = 'GET_ARTICLES_DATES_SUCCESS';
export const GET_ARTICLES_DATES_FAILED = 'GET_ARTICLES_DATES_FAILED';
export const GET_ARTICLE_DETAILS_START = 'GET_ARTICLE_DETAILS_START';
export const GET_ARTICLE_DETAILS_SUCCESS = 'GET_ARTICLE_DETAILS_SUCCESS';
export const GET_ARTICLE_DETAILS_FAILED = 'GET_ARTICLE_DETAILS_FAILED';
export const GET_ARTICLE_DELETED_DETAILS_START =
    'GET_ARTICLE_DELETED_DETAILS_START';
export const GET_ARTICLE_DELETED_DETAILS_SUCCESS =
    'GET_ARTICLE_DELETED_DETAILS_SUCCESS';
export const GET_ARTICLE_DELETED_DETAILS_FAILED =
    'GET_ARTICLE_DELETED_DETAILS_FAILED';
export const GET_ARTICLES_LIST_BY_DATE_START =
    'GET_ARTICLES_LIST_BY_DATE_START';
export const GET_ARTICLES_LIST_BY_DATE_SUCCESS =
    'GET_ARTICLES_LIST_BY_DATE_SUCCESS';
export const GET_ARTICLES_LIST_BY_DATE_FAILED =
    'GET_ARTICLES_LIST_BY_DATE_FAILED';
export const REMOVE_ITEM_FROM_ARTICLE_DATA_BY_ID =
    'REMOVE_ITEM_FROM_ARTICLE_DATA_BY_ID';
export const SET_ARTICLE_FAVOURITE_START = 'SET_ARTICLE_FAVOURITE_START';
export const SET_ARTICLE_FAVOURITE_SUCCESS = 'SET_ARTICLE_FAVOURITE_SUCCESS';
export const SET_ARTICLE_FAVOURITE_FAILED = 'SET_ARTICLE_FAVOURITE_FAILED';
export const REMOVE_ARTICLE_FAVOURITE_START = 'REMOVE_ARTICLE_FAVOURITE_START';
export const REMOVE_ARTICLE_FAVOURITE_SUCCESS =
    'REMOVE_ARTICLE_FAVOURITE_SUCCESS';
export const REMOVE_ARTICLE_FAVOURITE_FAILED =
    'REMOVE_ARTICLE_FAVOURITE_FAILED';
export const SET_ARTICLE_LIKE_START = 'SET_ARTICLE_LIKE_START';
export const SET_ARTICLE_LIKE_SUCCESS = 'SET_ARTICLE_LIKE_SUCCESS';
export const SET_ARTICLE_LIKE_FAILED = 'SET_ARTICLE_LIKE_FAILED';
export const REMOVE_ARTICLE_LIKE_START = 'REMOVE_ARTICLE_LIKE_START';
export const REMOVE_ARTICLE_LIKE_SUCCESS = 'REMOVE_ARTICLE_LIKE_SUCCESS';
export const REMOVE_ARTICLE_LIKE_FAILED = 'REMOVE_ARTICLE_LIKE_FAILED';
export const SET_ARTICLE_DISLIKE_START = 'SET_ARTICLE_DISLIKE_START';
export const SET_ARTICLE_DISLIKE_SUCCESS = 'SET_ARTICLE_DISLIKE_SUCCESS';
export const SET_ARTICLE_DISLIKE_FAILED = 'SET_ARTICLE_DISLIKE_FAILED';
export const REMOVE_ARTICLE_DISLIKE_START = 'REMOVE_ARTICLE_DISLIKE_START';
export const REMOVE_ARTICLE_DISLIKE_SUCCESS = 'REMOVE_ARTICLE_DISLIKE_SUCCESS';
export const REMOVE_ARTICLE_DISLIKE_FAILED = 'REMOVE_ARTICLE_DISLIKE_FAILED';
export const MASS_REMOVE_ARTICLES_START = 'MASS_REMOVE_ARTICLES_START';
export const MASS_REMOVE_ARTICLES_SUCCESS = 'MASS_REMOVE_ARTICLES_SUCCESS';
export const MASS_REMOVE_ARTICLES_FAILED = 'MASS_REMOVE_ARTICLES_FAILED';
export const GET_ARTICLES_DETAILS_START = 'GET_ARTICLES_DETAILS_START';
export const GET_ARTICLES_DETAILS_SUCCESS = 'GET_ARTICLES_DETAILS_SUCCESS';
export const GET_ARTICLES_DETAILS_FAILED = 'GET_ARTICLES_DETAILS_FAILED';
export const GET_ARTICLES_LIST_START = 'GET_ARTICLES_LIST_START';
export const GET_ARTICLES_LIST_SUCCESS = 'GET_ARTICLES_LIST_SUCCESS';
export const GET_ARTICLES_LIST_FAILED = 'GET_ARTICLES_LIST_FAILED';
export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAILED = 'CREATE_ARTICLE_FAILED';
export const UPDATE_ARTICLE_START = 'UPDATE_ARTICLE_START';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_FAILED = 'UPDATE_ARTICLE_FAILED';
export const DELETE_ARTICLE_START = 'DELETE_ARTICLE_START';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_FAILED = 'DELETE_ARTICLE_FAILED';
export const CHANGE_ARTICLES_START = 'CHANGE_ARTICLES_START';
export const CHANGE_ARTICLES_SUCCESS = 'CHANGE_ARTICLES_SUCCESS';
export const CHANGE_ARTICLES_FAILED = 'CHANGE_ARTICLES_FAILED';
export const RECOVER_DELETED_ARTICLE_START = 'RECOVER_DELETED_ARTICLE_START';
export const RECOVER_DELETED_ARTICLE_SUCCESS =
    'RECOVER_DELETED_ARTICLE_SUCCESS';
export const RECOVER_DELETED_ARTICLE_FAILED = 'RECOVER_DELETED_ARTICLE_FAILED';
export const ERASE_DELETED_ARTICLE_START = 'ERASE_DELETED_ARTICLE_START';
export const ERASE_DELETED_ARTICLE_SUCCESS = 'ERASE_DELETED_ARTICLE_SUCCESS';
export const ERASE_DELETED_ARTICLE_FAILED = 'ERASE_DELETED_ARTICLE_FAILED';
export const UPDATE_ARTICLE_STATUS = 'UPDATE_ARTICLE_STATUS';
export const CLEAR_ARTICLE_DETAILS = 'CLEAR_ARTICLE_DETAILS';

export const CREATE_FUNCTION_START = 'CREATE_FUNCTION_START';
export const CREATE_FUNCTION_SUCCESS = 'CREATE_FUNCTION_SUCCESS';
export const CREATE_FUNCTION_FAILED = 'CREATE_FUNCTION_FAILED';
export const UPDATE_FUNCTION_START = 'UPDATE_FUNCTION_START';
export const UPDATE_FUNCTION_SUCCESS = 'UPDATE_FUNCTION_SUCCESS';
export const UPDATE_FUNCTION_FAILED = 'UPDATE_FUNCTION_FAILED';
export const CLEAR_FUNCTION_DETAILS = 'CLEAR_FUNCTION_DETAILS';
export const GET_PROFILE_INFO_START = 'GET_PROFILE_INFO_START';
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS';
export const GET_PROFILE_INFO_FAILED = 'GET_PROFILE_INFO_FAILED';
export const UPDATE_PROFILE_SUBSCRIPTIONS_START =
    'UPDATE_PROFILE_SUBSCRIPTIONS_START';
export const UPDATE_PROFILE_SUBSCRIPTIONS_SUCCESS =
    'UPDATE_PROFILE_SUBSCRIPTIONS_SUCCESS';
export const UPDATE_PROFILE_SUBSCRIPTIONS_FAILED =
    'UPDATE_PROFILE_SUBSCRIPTIONS_FAILED';
export const UPDATE_PROFILE_INFO_START = 'UPDATE_PROFILE_INFO_START';
export const UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS';
export const UPDATE_PROFILE_INFO_FAILED = 'UPDATE_PROFILE_INFO_FAILED';
export const UPDATE_NEWS_PAGE = 'UPDATE_NEWS_PAGE';
export const UPDATE_AGREEMENT_PAGE = 'UPDATE_AGREEMENT_PAGE';
export const UPDATE_USERS_PAGE = 'UPDATE_USERS_PAGE';

export const USER_ENTRANCE_UPDATE = 'USER_ENTRANCE_UPDATE';

export const GET_ACCESS_RULES_START = 'GET_ACCESS_RULES_START';
export const GET_ACCESS_RULES_SUCCESS = 'GET_ACCESS_RULES_SUCCESS';
export const GET_ACCESS_RULES_FAILED = 'GET_ACCESS_RULES_FAILED';
export const SET_LOGIN_START = 'SET_LOGIN_START';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_FAILED = 'SET_LOGIN_FAILED';
export const REMOVE_FIRST_LOGIN = 'REMOVE_FIRST_LOGIN';

export const GET_USER_NOTIFICATIONS_START = 'GET_USER_NOTIFICATIONS_START';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_FAILED = 'GET_USER_NOTIFICATIONS_FAILED';
export const SET_USER_NOTIFICATIONS_READ_START =
    'SET_USER_NOTIFICATIONS_READ_START';
export const SET_USER_NOTIFICATIONS_READ_SUCCESS =
    'SET_USER_NOTIFICATIONS_READ_SUCCESS';
export const SET_USER_NOTIFICATIONS_READ_FAILED =
    'SET_USER_NOTIFICATIONS_READ_FAILED';
export const SET_USER_NOTIFICATIONS_READ_ALL_START =
    'SET_USER_NOTIFICATIONS_READ_ALL_START';
export const SET_USER_NOTIFICATIONS_READ_ALL_SUCCESS =
    'SET_USER_NOTIFICATIONS_READ_ALL_SUCCESS';
export const SET_USER_NOTIFICATIONS_READ_ALL_FAILED =
    'SET_USER_NOTIFICATIONS_READ_ALL_FAILED';
export const CLEAR_USER_NOTIFICATIONS = 'CLEAR_USER_NOTIFICATIONS';
export const LOCAL_UPDATE_PROFILE_INFO = 'LOCAL_UPDATE_PROFILE_INFO';
export const GET_USER_DETAILS_START = 'GET_USER_DETAILS_START';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const GET_ASUZ_DETAILS_START = 'GET_ASUZ_DETAILS_START';
export const GET_ASUZ_DETAILS_SUCCESS = 'GET_ASUZ_DETAILS_SUCCESS';
export const GET_ASUZ_DETAILS_FAILED = 'GET_ASUZ_DETAILS_FAILED';
export const CREATE_ASUZ_TASK_START = 'CREATE_ASUZ_TASK_START';
export const CREATE_ASUZ_TASK_SUCCESS = 'CREATE_ASUZ_TASK_SUCCESS';
export const CREATE_ASUZ_TASK_FAILED = 'CREATE_ASUZ_TASK_FAILED';
export const UPDATE_ASUZ_TASK_START = 'UPDATE_ASUZ_TASK_START';
export const UPDATE_ASUZ_TASK_SUCCESS = 'UPDATE_ASUZ_TASK_SUCCESS';
export const UPDATE_ASUZ_TASK_FAILED = 'UPDATE_ASUZ_TASK_FAILED';
export const DELETE_ASUZ_TASK_START = 'DELETE_ASUZ_TASK_START';
export const DELETE_ASUZ_TASK_SUCCESS = 'DELETE_ASUZ_TASK_SUCCESS';
export const DELETE_ASUZ_TASK_FAILED = 'DELETE_ASUZ_TASK_FAILED';
export const REJECT_ASUZ_TASK_START = 'REJECT_ASUZ_TASK_START';
export const REJECT_ASUZ_TASK_SUCCESS = 'REJECT_ASUZ_TASK_SUCCESS';
export const REJECT_ASUZ_TASK_FAILED = 'REJECT_ASUZ_TASK_FAILED';
export const UPDATE_ASUZ_TASK_STATUS_START = 'UPDATE_ASUZ_TASK_STATUS_START';
export const UPDATE_ASUZ_TASK_STATUS_SUCCESS =
    'UPDATE_ASUZ_TASK_STATUS_SUCCESS';
export const UPDATE_ASUZ_TASK_STATUS_FAILED = 'UPDATE_ASUZ_TASK_STATUS_FAILED';
export const CLEAR_ASUZ_DETAILS = 'CLEAR_ASUZ_DETAILS';
export const GET_ENTERPRISE_STRUCTURE_START = 'GET_ENTERPRISE_STRUCTURE_START';
export const GET_ENTERPRISE_STRUCTURE_SUCCESS =
    'GET_ENTERPRISE_STRUCTURE_SUCCESS';
export const GET_ENTERPRISE_STRUCTURE_FAILED =
    'GET_ENTERPRISE_STRUCTURE_FAILED';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const GET_QUESTIONS_START = 'GET_QUESTIONS_START';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILED = 'GET_QUESTIONS_FAILED';

export const GET_APPROVAL_DETAILS_START = 'GET_APPROVAL_DETAILS_START';
export const GET_APPROVAL_DETAILS_SUCCESS = 'GET_APPROVAL_DETAILS_SUCCESS';
export const GET_APPROVAL_DETAILS_FAILED = 'GET_APPROVAL_DETAILS_FAILED';
export const UPDATE_ERRORS_PAGE = 'UPDATE_ERRORS_PAGE';
export const GET_ERRORS_START = 'GET_ERRORS_START';
export const GET_ERRORS_SUCCESS = 'GET_ERRORS_SUCCESS';
export const GET_ERRORS_FAILED = 'GET_ERRORS_FAILED';
export const GET_ERROR_DATA_START = 'GET_ERROR_DATA_START';
export const GET_ERROR_DATA_SUCCESS = 'GET_ERROR_DATA_SUCCESS';
export const GET_ERROR_DATA_FAILED = 'GET_ERROR_DATA_FAILED';
export const CLEAR_ERROR_DATA = 'CLEAR_ERROR_DATA';

export const UPDATE_MEDIA_LIST = 'UPDATE_MEDIA_LIST';
export const GET_MEDIA_START = 'GET_MEDIA_START';
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS';
export const GET_MEDIA_FAILED = 'GET_MEDIA_FAILED';
export const UPDATE_ARTICLE_DETAILS_START = 'UPDATE_ARTICLE_DETAILS_START';
export const UPDATE_ARTICLE_DETAILS_SUCCESS = 'UPDATE_ARTICLE_DETAILS_SUCCESS';
export const GET_MEDIA_LIST_START = 'GET_MEDIA_LIST_START';
export const GET_MEDIA_LIST_SUCCESS = 'GET_MEDIA_LIST_SUCCESS';
export const GET_MEDIA_LIST_FAILED = 'GET_MEDIA_LIST_FAILED';
export const SET_EMPTY_LETTERS_LIST = 'SET_EMPTY_LETTERS_LIST';
export const SET_EMPTY_ARTICLES_LIST = 'SET_EMPTY_ARTICLES_LIST';
export const SET_EMPTY_MEDIA_LIST = 'SET_EMPTY_MEDIA_LIST';

export const GET_ALL_DRAFT_SAVES_START = 'GET_ALL_DRAFT_SAVES_START';
export const GET_ALL_DRAFT_SAVES_SUCCESS = 'GET_ALL_DRAFT_SAVES_SUCCESS';
export const GET_ALL_DRAFT_SAVES_FAILED = 'GET_ALL_DRAFT_SAVES_FAILED';
export const GET_DRAFT_SAVE_START = 'GET_DRAFT_SAVE_START';
export const GET_DRAFT_SAVE_SUCCESS = 'GET_DRAFT_SAVE_SUCCESS';
export const GET_DRAFT_SAVE_FAILED = 'GET_DRAFT_SAVE_FAILED';
export const CREATE_DRAFT_SAVE_START = 'CREATE_DRAFT_SAVE_START';
export const CREATE_DRAFT_SAVE_SUCCESS = 'CREATE_DRAFT_SAVE_SUCCESS';
export const CREATE_DRAFT_SAVE_FAILED = 'CREATE_DRAFT_SAVE_FAILED';
export const UPDATE_DRAFT_SAVE_START = 'UPDATE_DRAFT_SAVE_START';
export const UPDATE_DRAFT_SAVE_SUCCESS = 'UPDATE_DRAFT_SAVE_SUCCESS';
export const UPDATE_DRAFT_SAVE_FAILED = 'UPDATE_DRAFT_SAVE_FAILED';
export const DELETE_DRAFT_SAVE_START = 'DELETE_DRAFT_SAVE_START';
export const DELETE_DRAFT_SAVE_SUCCESS = 'DELETE_DRAFT_SAVE_SUCCESS';
export const DELETE_DRAFT_SAVE_FAILED = 'DELETE_DRAFT_SAVE_FAILED';
export const UPDATE_DRAFT_SAVE_ID = 'UPDATE_DRAFT_SAVE_ID';
export const CLEAR_DRAFT_SAVE = 'CLEAR_DRAFT_SAVE';

export const GET_APPROVALS_COUNT_START = 'GET_APPROVALS_COUNT_START';
export const GET_APPROVALS_COUNT_SUCCESS = 'GET_APPROVALS_COUNT_SUCCESS';
export const GET_APPROVALS_COUNT_FAILED = 'GET_APPROVALS_COUNT_FAILED';

export const GET_SCHEDULE_START = 'GET_SCHEDULE_START';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAILED = 'GET_SCHEDULE_FAILED';
export const CREATE_SCHEDULE_START = 'CREATE_SCHEDULE_START';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED';
export const UPDATE_SCHEDULE_START = 'UPDATE_SCHEDULE_START';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAILED = 'UPDATE_SCHEDULE_FAILED';
export const CLEAR_SCHEDULE_DETAILS = 'CLEAR_SCHEDULE_DETAILS';

export const GET_MY_ARTICLES_COUNT_START = 'GET_MY_ARTICLES_COUNT_START';
export const GET_MY_ARTICLES_COUNT_SUCCESS = 'GET_MY_ARTICLES_COUNT_SUCCESS';
export const GET_MY_ARTICLES_COUNT_FAILED = 'GET_MY_ARTICLES_COUNT_FAILED';

export const GET_UNFINISHED_EDIT_START = 'GET_UNFINISHED_EDIT_START';
export const GET_UNFINISHED_EDIT_SUCCESS = 'GET_UNFINISHED_EDIT_SUCCESS';
export const GET_UNFINISHED_EDIT_FAILED = 'GET_UNFINISHED_EDIT_FAILED';
export const CREATE_UNFINISHED_EDIT_START = 'CREATE_UNFINISHED_EDIT_START';
export const CREATE_UNFINISHED_EDIT_SUCCESS = 'CREATE_UNFINISHED_EDIT_SUCCESS';
export const CREATE_UNFINISHED_EDIT_FAILED = 'CREATE_UNFINISHED_EDIT_FAILED';
export const DELETE_UNFINISHED_EDIT_START = 'DELETE_UNFINISHED_EDIT_START';
export const DELETE_UNFINISHED_EDIT_SUCCESS = 'DELETE_UNFINISHED_EDIT_SUCCESS';
export const DELETE_UNFINISHED_EDIT_FAILED = 'DELETE_UNFINISHED_EDIT_FAILED';
export const CLEAR_UNFINISHED_EDIT = 'CLEAR_UNFINISHED_EDIT';

export const UPDATE_LETTERS_POSM_DATES_START =
    'UPDATE_LETTERS_POSM_DATES_START';
export const UPDATE_LETTERS_POSM_DATES_SUCCESS =
    'UPDATE_LETTERS_POSM_DATES_SUCCESS';
export const UPDATE_LETTERS_POSM_DATES_FAILED =
    'UPDATE_LETTERS_POSM_DATES_FAILED';

export const GET_ERROR_NOTIFIES_START = 'GET_ERROR_NOTIFIES_START';
export const GET_ERROR_NOTIFIES_SUCCESS = 'GET_ERROR_NOTIFIES_SUCCESS';
export const GET_ERROR_NOTIFIES_FAILED = 'GET_ERROR_NOTIFIES_FAILED';

export const GET_REQUEST_START = 'GET_REQUEST_START';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_FAILED = 'GET_REQUEST_FAILED';
export const CREATE_REQUEST_START = 'CREATE_REQUEST_START';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAILED = 'CREATE_REQUEST_FAILED';
export const UPDATE_REQUEST_START = 'UPDATE_REQUEST_START';
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILED = 'UPDATE_REQUEST_FAILED';
export const UPDATE_REQUEST_PAGE = 'UPDATE_REQUEST_PAGE';
export const GET_REQUEST_COUNT_START = 'GET_REQUEST_COUNT_START';
export const GET_REQUEST_COUNT_SUCCESS = 'GET_REQUEST_COUNT_SUCCESS';
export const GET_REQUEST_COUNT_FAILED = 'GET_REQUEST_COUNT_FAILED';
export const CLEAR_REQUEST_DATA = 'CLEAR_REQUEST_DATA';
export const GET_ATTACHMENT_DATA_START = 'GET_ATTACHMENT_DATA_START';
export const GET_ATTACHMENT_DATA_SUCCESS = 'GET_ATTACHMENT_DATA_SUCCESS';
export const GET_ATTACHMENT_DATA_FAILED = 'GET_ATTACHMENT_DATA_FAILED';
export const CLEAR_ATTACHMENT_DATA = 'CLEAR_ATTACHMENT_DATA';
export const TOGGLE_NAV_BAR_CONCEPT = 'TOGGLE_NAV_BAR_CONCEPT';
