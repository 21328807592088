import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const TrashIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 14 14">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.10879 2.13325L4.55684 0.7891C4.64759 0.516872 4.90235 0.333252 5.1893 0.333252H8.7893C9.07625 0.333252 9.33101 0.516872 9.42175 0.7891L9.8698 2.13325H12.9893C13.3575 2.13325 13.6559 2.43173 13.6559 2.79992C13.6559 3.16811 13.3575 3.46659 12.9893 3.46659H12.4179L11.9286 11.784C11.8665 12.8412 10.991 13.6666 9.93209 13.6666H4.04651C2.98755 13.6666 2.11214 12.8412 2.04996 11.784L1.5607 3.46659H0.999919C0.631729 3.46659 0.333252 3.16811 0.333252 2.79992C0.333252 2.43173 0.631729 2.13325 0.999919 2.13325H4.10879ZM5.51425 2.13325H8.46435L8.30879 1.66659H5.66981L5.51425 2.13325ZM11.0823 3.46659H2.89633L3.38099 11.7057C3.40172 12.0581 3.69352 12.3333 4.04651 12.3333H9.93209C10.2851 12.3333 10.5769 12.0581 10.5976 11.7057L11.0823 3.46659ZM8.12393 5.15833C8.1469 4.79086 8.46341 4.51158 8.83088 4.53455C9.19836 4.55752 9.47763 4.87403 9.45467 5.2415L9.15467 10.0415C9.1317 10.409 8.81519 10.6883 8.44771 10.6653C8.08024 10.6423 7.80096 10.3258 7.82393 9.95833L8.12393 5.15833ZM6.15467 9.95833C6.17763 10.3258 5.89836 10.6423 5.53088 10.6653C5.16341 10.6883 4.8469 10.409 4.82393 10.0415L4.52393 5.2415C4.50096 4.87403 4.78024 4.55752 5.14771 4.53455C5.51519 4.51158 5.8317 4.79086 5.85467 5.15833L6.15467 9.95833Z"
        />
    </SvgIcon>
);
