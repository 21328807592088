import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../helpers/routes';
import Loading from '../../components/CommonParts/Loading';
import Header from '../../components/CommonParts/Header';
import ErrorBoundary from '../../components/CommonParts/ErrorBoundary';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAccessRules,
    postSetLogin,
    removeFirstLogin,
} from '../../actions/accessRulesAction';
import { getMyArticlesCount } from '../../actions/myArticlesAction';
import { getErrorsNotifies } from '../../actions/errorsAction';
import { getRequestCount } from '../../actions/requestAction';
import { checkSavedUrl } from '../../helpers/m';
import {
    can_approval_page_view,
    can_im_create,
    can_task_page_view,
    can_user_page_view,
    can_new_create,
    can_report_page_view,
    can_function_page_view,
    can_trash_page_view,
    can_bag_report_page_view,
    can_new_edit,
    can_request_info_admin,
    can_request_info_page_view,
} from '../../helpers/permissions';
import CookiesModal from '../../components/CommonParts/CookiesModal';
import styles from './main.module.scss';

const NavBar = lazy(() => import('../../components/CommonParts/NavBar'));
const NewsPage = lazy(() => import('../../pages/news'));
const EmptyPage = lazy(() => import('../../pages/empty'));
const NewsEditPage = lazy(() => import('../../pages/newsEdit'));
const KnowledgeBasePage = lazy(() => import('../../pages/knowledgebase'));
const Letters = lazy(() => import('../../pages/letters'));
const LetterEdit = lazy(() => import('../../pages/letterEdit'));
const ArticleEdit = lazy(() => import('../../pages/articleEdit'));
const FeedPage = lazy(() => import('../../pages/feed'));
const MainPage = lazy(() => import('../../pages/main'));
const FunctionsPage = lazy(() => import('../../pages/functions'));
const ProfilePage = lazy(() => import('../../pages/userProfile'));
const AgreementPage = lazy(() => import('../../pages/agreement'));
const FavoritesPage = lazy(() => import('../../pages/favorites'));
const SubscriptionsPage = lazy(() => import('../../pages/subscriptions'));
const VideosPage = lazy(() => import('../../pages/videos'));
const UsersPage = lazy(() => import('../../pages/users'));
const UserEditPage = lazy(() => import('../../pages/userEdit'));
const ReportPage = lazy(() => import('../../pages/reports'));
const AsuzEditPage = lazy(() => import('../../pages/asuzTaskEdit'));
const AsuzPage = lazy(() => import('../../pages/asuz'));
const NewsViewPage = lazy(() => import('../../pages/newsView'));
const ErrorReportsPage = lazy(() => import('../../pages/errorReports'));
const BasketPage = lazy(() => import('../../pages/basket'));
const DraftsPage = lazy(() => import('../../pages/drafts'));
const MyArticlesPage = lazy(() => import('../../pages/myArticles'));
const WelcomeSubscriptions = lazy(() =>
    import('../../components/CommonParts/WelcomeSubscriptions'),
);
const SearchPage = lazy(() => import('../../pages/search'));
const SchedulesPage = lazy(() => import('../../pages/schedules'));
const ScheduleEditPage = lazy(() => import('../../pages/scheduleEdit'));
const ConsultantPage = lazy(() => import('../../pages/consultant'));
const NewsEditFullPage = lazy(() => import('../../pages/newsEditFull'));
const RequestPage = lazy(() => import('../../pages/requestPage'));
const RequestViewPage = lazy(() => import('../../pages/requestView'));
const AttachmentViewPage = lazy(() => import('../../pages/attachmentView'));

const MAIN_ROUTES = [
    {
        url: '/news',
        component: NewsPage,
        exact: true,
        permission: can_new_create,
    },
    {
        url: '/news/create',
        component: NewsEditPage,
        exact: true,
        permission: can_new_create,
    },
    {
        url: '/news/edit/:id',
        component: NewsEditPage,
        exact: true,
        permission: can_new_edit,
    },
    {
        url: '/news_pure/create',
        component: NewsEditFullPage,
        exact: true,
        permission: can_new_create,
    },
    {
        url: '/news_pure/edit/:id',
        component: NewsEditFullPage,
        exact: true,
        permission: can_new_edit,
    },
    { url: '/news/:id', component: NewsViewPage, exact: true },
    { url: '/knowledgebase', component: KnowledgeBasePage, exact: false },
    { url: '/letter', component: Letters, exact: true },
    { url: '/letter/create', component: LetterEdit, exact: true },
    { url: '/letter/edit/:id', component: LetterEdit, exact: true },
    { url: '/article/create', component: ArticleEdit, exact: true },
    { url: '/article/edit/:id', component: ArticleEdit, exact: true },
    { url: '/letter/:id', component: Letters, exact: false },
    { url: '/main', component: MainPage, exact: true },
    { url: '/feed', component: FeedPage, exact: true },
    {
        url: '/functions',
        component: FunctionsPage,
        permission: can_function_page_view,
        exact: false,
    },
    { url: '/profile', component: ProfilePage, exact: true },
    {
        url: '/onapproval/:type/:id/approvals/:user/:action',
        component: AgreementPage,
        permission: can_approval_page_view,
        exact: true,
    },
    {
        url: '/onapproval',
        component: AgreementPage,
        permission: can_approval_page_view,
        exact: true,
    },
    { url: '/favorites', component: FavoritesPage, exact: true },
    { url: '/subscriptions', component: SubscriptionsPage, exact: true },
    { url: '/videos', component: VideosPage, exact: true },
    {
        url: '/users',
        component: UsersPage,
        permission: can_user_page_view,
        exact: true,
    },
    {
        url: '/user/edit/:id',
        component: UserEditPage,
        permission: can_user_page_view,
        exact: true,
    },
    {
        url: '/reports',
        component: ReportPage,
        permission: can_report_page_view,
        exact: true,
    },
    {
        url: '/asuz',
        component: AsuzPage,
        exact: true,
        permission: can_task_page_view,
    },
    {
        url: '/asuz/create',
        component: AsuzEditPage,
        exact: true,
        permission: can_im_create,
    },
    {
        url: '/asuz/edit/:id',
        component: AsuzEditPage,
        exact: true,
        permission: can_im_create,
    },
    {
        url: '/asuz/:id',
        component: AsuzEditPage,
        exact: true,
    },
    {
        url: '/errors',
        component: ErrorReportsPage,
        exact: true,
        permission: can_bag_report_page_view,
    },
    {
        url: '/basket',
        component: BasketPage,
        exact: true,
        permission: can_trash_page_view,
    },
    {
        url: '/drafts',
        component: DraftsPage,
        exact: true,
        permission: can_trash_page_view,
    },
    {
        url: '/schedules',
        component: SchedulesPage,
        exact: true,
        permission: can_im_create,
    },
    {
        url: '/schedule/create',
        component: ScheduleEditPage,
        exact: true,
        permission: can_im_create,
    },
    {
        url: '/schedule/edit/:id',
        component: ScheduleEditPage,
        exact: true,
        permission: can_im_create,
    },
    {
        url: '/request',
        component: RequestPage,
        exact: true,
        permission: can_request_info_page_view,
    },
    {
        url: '/request/view/:id',
        component: RequestViewPage,
        exact: true,
        permission: can_request_info_page_view,
    },
    {
        url: '/attachment/:id',
        component: AttachmentViewPage,
        exact: true,
    },
    { url: '/', pathname: '/main', redirect: true, exact: true },
    { url: '/settings', component: EmptyPage, exact: true },
    { url: '/articles-in-progress', component: MyArticlesPage, exact: true },
    { url: '/search', component: SearchPage, exact: true },
    { url: '/consultant', component: ConsultantPage, exact: true },
];

export function MainContainer() {
    const { rules, isFirstLogin, fetching } = useSelector(
        (state) => state.accessRulesReducer,
    );
    const userId = useSelector((state) => state.profileReducer.profile?.id);
    const dispatch = useDispatch();

    useEffect(() => {
        if (checkSavedUrl()) return;
        dispatch(getAccessRules());
    }, []);

    useEffect(() => {
        if (userId) {
            dispatch(postSetLogin());
        }
    }, [userId]);

    useEffect(() => {
        if (rules && Object.values(rules)[0]) {
            dispatch(getMyArticlesCount());
            if (can_bag_report_page_view in rules) {
                dispatch(getErrorsNotifies());
            }
            if (can_request_info_admin in rules) {
                dispatch(getRequestCount());
            }
        }
    }, [rules]);

    const canView = (route) => {
        return route.permission ? rules[route.permission] : true;
    };

    const closeShowWelcome = () => {
        dispatch(removeFirstLogin());
    };

    return (
        <>
            {!fetching && (
                <Suspense fallback={<Loading />}>
                    {isFirstLogin && (
                        <WelcomeSubscriptions
                            open
                            onCancel={closeShowWelcome}
                            onSave={closeShowWelcome}
                        />
                    )}
                    <Header />
                    <div className={styles.mainComponentContainer}>
                        <div className={styles.mainInnerContainer}>
                            <NavBar />
                            <ErrorBoundary>
                                <Switch>
                                    {MAIN_ROUTES.map((route) => {
                                        if (route.redirect || !canView(route)) {
                                            return (
                                                <Route
                                                    key={`mca-${route.url}`}
                                                    path={route.url}
                                                    exact={route.exact}
                                                >
                                                    <Redirect
                                                        to={{
                                                            pathname:
                                                                route.pathname ||
                                                                DEFAULT_ROUTE,
                                                        }}
                                                    />
                                                </Route>
                                            );
                                        }
                                        return (
                                            <Route
                                                key={`mca-${route.url}`}
                                                path={route.url}
                                                exact={route.exact}
                                                component={route.component}
                                            />
                                        );
                                    })}
                                    <EmptyPage />
                                </Switch>
                            </ErrorBoundary>
                        </div>
                    </div>
                    <CookiesModal />
                </Suspense>
            )}
        </>
    );
}

export default MainContainer;
