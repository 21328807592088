import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const MoreIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 26">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 13.1428C7 11.9874 6.10457 11.0508 5 11.0508C3.89543 11.0508 3 11.9874 3 13.1428C3 14.2982 3.89543 15.2349 5 15.2349C6.10457 15.2349 7 14.2982 7 13.1428Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 13.1428C14 11.9874 13.1046 11.0508 12 11.0508C10.8954 11.0508 10 11.9874 10 13.1428C10 14.2982 10.8954 15.2349 12 15.2349C13.1046 15.2349 14 14.2982 14 13.1428Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 13.1428C21 11.9874 20.1046 11.0508 19 11.0508C17.8954 11.0508 17 11.9874 17 13.1428C17 14.2982 17.8954 15.2349 19 15.2349C20.1046 15.2349 21 14.2982 21 13.1428Z"
        />
    </SvgIcon>
);
