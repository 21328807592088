import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModalSearchResult from '../ModalSearchResults';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/grayX.svg';
import { CHAPTERS, SEARCH_FILTERS_DEFAULT } from '../../../helpers/constants';
import styles from './conceptHeaderSearch.module.scss';

const ConceptHeaderSearch = ({ isPerek = false, chapterId }) => {
    const [inputValue, setInputValue] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [error, setError] = useState(false);
    const [searchingPage, setSearchingPage] = useState();
    const isTablet = window.matchMedia('(max-width: 1439px)').matches;
    const history = useHistory();
    const initFilter = {
        ...SEARCH_FILTERS_DEFAULT,
        chapter_id: chapterId ?? CHAPTERS.CONCEPT,
    };
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (!inputValue || !inputValue.length) {
            setShowResults(false);
            // return;
        }
        setFilter({
            ...initFilter,
            q: inputValue,
        });
    }, [inputValue]);

    useEffect(() => {
        if (searchingPage && searchingPage !== history?.location?.pathname) {
            onClickClear();
            return;
        }
        setSearchingPage(history?.location?.pathname);
    }, [history?.location?.pathname]);

    const onChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        setShowResults(false);
        if (error && value?.length >= 3) {
            setError(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key !== 'Enter') return;
        if (inputValue?.length < 3) {
            return setError(true);
        }
        setShowResults(true);
    };

    const onClickSearch = () => {
        if (inputValue?.length < 3) {
            return setError(true);
        }
        setShowResults(true);
    };

    const onClickClear = () => {
        setInputValue('');
    };

    const closeModal = () => {
        setShowResults(false);
    };

    return (
        <div
            className={`${styles.wrapper} ${
                isPerek ? styles.wrapperPerek : ''
            }`}
        >
            <SearchIcon
                className={styles.searchInputIcon}
                onClick={onClickSearch}
            />
            <input
                value={inputValue}
                onChange={onChange}
                className={`${styles.searchInput} ${
                    inputValue ? styles.searchInputWithValue : ''
                }`}
                placeholder={isTablet ? 'Поиск по порталу' : 'Поиск'}
                onKeyDown={handleKeyDown}
            />
            {error && (
                <span className={styles.searchErrorHelper}>
                    Введите минимум 3 символа
                </span>
            )}
            {inputValue && inputValue.length > 0 && (
                <CloseIcon
                    className={styles.closeIcon}
                    onClick={onClickClear}
                />
            )}
            {showResults && (
                <ModalSearchResult
                    open={showResults}
                    filters={filter}
                    onCloseModal={closeModal}
                />
            )}
        </div>
    );
};

export default ConceptHeaderSearch;
