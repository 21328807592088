import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreIcon } from '../Icons';
import styles from './MoreMenu.module.scss';

function MoreMenu({ items, isVertical, customIcon }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const noItems = !items || items?.length < 1;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onLocalClick = (item) => {
        handleClose();
        item && item.onClick && item.onClick();
    };

    return (
        <div>
            <IconButton
                size="small"
                aria-label="more"
                id="more-button"
                aria-controls="more-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                disableTouchRipple={true}
                onClick={handleClick}
                className={styles.iconMoreMenu}
            >
                {customIcon ? (
                    customIcon
                ) : (
                    <MoreIcon
                        htmlColor="#92929D"
                        sx={{ transform: isVertical ? 'rotate(90deg)' : '' }}
                    />
                )}
            </IconButton>
            {!noItems && (
                <Menu
                    id="more-menu"
                    MenuListProps={{
                        'aria-labelledby': 'more-button',
                    }}
                    disableAutoFocusItem={true}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className={styles.menuContainer}
                >
                    {items.map((item) => (
                        <MenuItem
                            className={styles.menuItem}
                            key={item.name}
                            selected={item.name === 'Pyxis'}
                            onClick={() => onLocalClick(item)}
                            disableTouchRipple={true}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    );
}

MoreMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ).isRequired,
    isVertical: PropTypes.bool,
    customIcon: PropTypes.any,
};

MoreMenu.defaultProps = {
    isVertical: false,
    customIcon: false,
};

export default MoreMenu;
