import {
    TOGGLE_NAV_BAR,
    ERROR_MESSAGE_UPDATE,
    SUCCESS_MESSAGE_UPDATE,
    WARNING_MESSAGE_UPDATE,
    USER_ENTRANCE_UPDATE,
    TOGGLE_NAV_BAR_CONCEPT,
} from './actionTypes';

export const toggleNavBar = (payload) => ({
    type: TOGGLE_NAV_BAR,
    payload,
});

export const errorMessageUpdate = (payload) => ({
    type: ERROR_MESSAGE_UPDATE,
    payload,
});

export const successMessageUpdate = (payload) => ({
    type: SUCCESS_MESSAGE_UPDATE,
    payload,
});

export const warningMessageUpdate = (payload) => ({
    type: WARNING_MESSAGE_UPDATE,
    payload,
});

export const userEntranceUpdate = (payload) => ({
    type: USER_ENTRANCE_UPDATE,
    payload,
});

export const toggleNavBarConcept = (payload) => ({
    type: TOGGLE_NAV_BAR_CONCEPT,
    payload,
});
