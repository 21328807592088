import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../helpers/routes';
import Loading from '../../components/CommonParts/Loading';
import ErrorBoundary from '../../components/CommonParts/ErrorBoundary';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAccessRules,
    postSetLogin,
    removeFirstLogin,
} from '../../actions/accessRulesAction';
import { getMyArticlesCount } from '../../actions/myArticlesAction';
import { checkSavedUrl } from '../../helpers/m';
import CookiesModal from '../../components/CommonParts/CookiesModal';
import ConceptHeader from '../../components/CommonParts/ConceptHeader';
import styles from './concept.module.scss';

const EmptyPage = lazy(() => import('../../pages/empty'));
const ConceptMainPage = lazy(() => import('../../pages/conceptMainPage'));
const ConceptKnowledgebase = lazy(() =>
    import('../../pages/conceptKnowledgebase'),
);
const WelcomeSubscriptions = lazy(() =>
    import('../../components/CommonParts/WelcomeSubscriptions'),
);

const MAIN_ROUTES = [
    { url: '/concept', component: ConceptMainPage, exact: true },
    { url: '/concept/base', component: ConceptKnowledgebase },
];

export function ConceptContainer() {
    const { rules, isFirstLogin, fetching } = useSelector(
        (state) => state.accessRulesReducer,
    );
    const userId = useSelector((state) => state.profileReducer.profile?.id);
    const dispatch = useDispatch();

    useEffect(() => {
        if (checkSavedUrl()) return;
        dispatch(getAccessRules());
    }, []);

    useEffect(() => {
        if (userId) {
            dispatch(postSetLogin());
        }
    }, [userId]);

    useEffect(() => {
        if (rules && Object.values(rules)[0]) {
            dispatch(getMyArticlesCount());
        }
    }, [rules]);

    const canView = (route) => {
        return route.permission ? rules[route.permission] : true;
    };

    const closeShowWelcome = () => {
        dispatch(removeFirstLogin());
    };

    return (
        <>
            {!fetching && (
                <Suspense fallback={<Loading />}>
                    {isFirstLogin && (
                        <WelcomeSubscriptions
                            open
                            onCancel={closeShowWelcome}
                            onSave={closeShowWelcome}
                        />
                    )}
                    <ConceptHeader />
                    <div className={styles.mainComponentContainer}>
                        <div className={styles.mainInnerContainer}>
                            <ErrorBoundary>
                                <Switch>
                                    {MAIN_ROUTES.map((route) => {
                                        if (route.redirect || !canView(route)) {
                                            return (
                                                <Route
                                                    key={`con-mca-${route.url}`}
                                                    path={route.url}
                                                    exact={route.exact}
                                                >
                                                    <Redirect
                                                        to={{
                                                            pathname:
                                                                route.pathname ||
                                                                DEFAULT_ROUTE,
                                                        }}
                                                    />
                                                </Route>
                                            );
                                        }
                                        return (
                                            <Route
                                                key={`con-mca-${route.url}`}
                                                path={route.url}
                                                exact={route.exact}
                                                component={route.component}
                                            />
                                        );
                                    })}
                                    <EmptyPage />
                                </Switch>
                            </ErrorBoundary>
                        </div>
                    </div>
                    <CookiesModal />
                </Suspense>
            )}
        </>
    );
}

export default ConceptContainer;
