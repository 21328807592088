import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const StarIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.55471 22.8321C5.8355 23.3115 4.88782 22.7143 5.01006 21.8586L5.93935 15.3536L1.29291 10.7071C0.710217 10.1244 1.04283 9.12659 1.85859 9.01005L8.30225 8.08953L11.0809 1.60608C11.4272 0.797973 12.5728 0.797973 12.9192 1.60608L15.6978 8.08953L22.1414 9.01005C22.9572 9.12659 23.2898 10.1244 22.7071 10.7071L18.0607 15.3536L18.99 21.8586C19.1122 22.7143 18.1645 23.3115 17.4453 22.8321L12 19.2019L6.55471 22.8321ZM7.30626 19.9273L11.4453 17.1679C11.7812 16.944 12.2188 16.944 12.5547 17.1679L16.6938 19.9273L16.0101 15.1414C15.9655 14.8298 16.0703 14.5155 16.2929 14.2929L19.8787 10.7071L14.8586 9.98995C14.5131 9.94059 14.2183 9.71469 14.0809 9.39392L12 4.53859L9.91916 9.39392C9.78168 9.71469 9.48692 9.94059 9.14143 9.98995L4.12133 10.7071L7.70712 14.2929C7.92969 14.5155 8.03448 14.8298 7.98996 15.1414L7.30626 19.9273Z"
        />
    </SvgIcon>
);
